<template>
  <span v-if="user && profile">
    <v-row class="my-3">
      <v-col cols="12" md="6">
        <v-card class="pa-3">
          <v-card-title primary-title> Mon profil </v-card-title>
          <v-card-text>
            <v-row class="mt-3">
              <v-col cols="12">
                <v-text-field
                  label="Nom"
                  v-model="profile.lastname"
                ></v-text-field>
                <v-text-field
                  label="Prenom"
                  v-model="profile.firstname"
                ></v-text-field>
                <v-text-field
                  disabled
                  label="Email"
                  v-model="profile.email"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn color="primary" outlined @click="saveChanges"
                  >Sauvegarder les changements</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" v-if="profile.role == 'User'">
        <v-card class="pa-3" v-if="profile.team && currentTeam">
          <v-card-title primary-title> Changer d'équipe </v-card-title>
          <v-card-text>
            <v-row class="mt-3">
              <v-col cols="12">
                <v-text-field
                  disabled
                  label="Équipe actuelle"
                  v-model="currentTeam.name"
                ></v-text-field>
                <v-text-field
                  color="accent"
                  label="Code"
                  v-model="teamCode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn color="accent" outlined @click="joinTeam">Valider</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="pa-3" v-else>
          <v-card-title primary-title> Rejoindre une équipe </v-card-title>
          <v-card-text>
            <v-row class="mt-3">
              <v-col cols="12">
                <v-text-field
                  color="accent"
                  label="Code"
                  v-model="teamCode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="d-flex justify-center">
                <v-btn color="accent" outlined @click="joinTeam"
                  >Rejoindre</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" v-else>
        <v-card class="pa-3" v-if="profile.role">
          <v-card-title primary-title> Votre rôle : </v-card-title>
          <v-card-text>
            <h3 v-text="profile.role"></h3>
          </v-card-text>
        </v-card>
        <v-card class="mt-5 pa-3" v-if="profile.role">
          <v-card-title primary-title> Changer de mot de passe : </v-card-title>
          <v-card-text>
            <v-col cols="12">
              <v-text-field
                type="password"
                label="Nouveau mot de passe"
                v-model="newPass1"
              ></v-text-field>
              <v-text-field
                type="password"
                label="Confirmer le mot de passe"
                v-model="newPass2"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="d-flex justify-center">
              <v-btn color="accent" outlined @click="changePassword"
                >Changer de mot de passe</v-btn
              >
            </v-col>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import { getAuth, updatePassword } from "firebase/auth";

import Swal from "sweetalert2/dist/sweetalert2.js";

import Profile from "@/classes/Profile";
import Team from "@/classes/Team";

export default {
  name: "Home",
  props: ["user", "profile"],
  setup() {
    return {
      auth: getAuth(),
    };
  },
  data() {
    return {
      unsub: [],
      teamCode: "",
      currentTeam: null,
      newPass1: "",
      newPass2: "",
    };
  },
  created() {
    if (this.profile && this.profile.team) {
      this.unsub.push(
        Team.listenById(this.profile.team, (team) => {
          this.currentTeam = team;
        })
      );
    }
  },
  methods: {
    saveChanges() {
      this.profile.save();
    },
    async joinTeam() {
      if (this.profile.team) {
        let oldTeam = await Team.getById(this.profile.team);
        oldTeam.members = oldTeam.members.filter(
          (member) => member.id != this.profile.id
        );
        await oldTeam.save();
      }
      let newTeam = await Team.getByCode(this.teamCode);
      if (newTeam && newTeam.id) {
        if (this.profile.team != newTeam.id) {
          this.profile.team = newTeam.id;
          await this.profile.save();
          newTeam.members.push({
            id: this.profile.id,
            job: "Indéfini",
          });
          await newTeam.save();
          window.location.reload();
        }
        this.teamCode = "";
        Swal.fire({
          icon: "error",
          title: "Vous appartenez déjà à " + newTeam.name,
          confirmButtonText: "Ok",
        });
      }
    },
    async changePassword() {
      if (this.newPass1 == this.newPass2) {
        if (this.newPass1.length >= 6) {
          await updatePassword(this.auth.currentUser, this.newPass1);
          Swal.fire({
            icon: "success",
            title: "Votre mot de passe a bien été changé",
            confirmButtonText: "Ok",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Le mot de passe doit contenir au moins 6 caractères",
            confirmButtonText: "Ok",
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Les mots de passe ne correspondent pas",
          confirmButtonText: "Ok",
        });
      }
    },
  },
  destroyed() {
    this.unsub.forEach((unsub) => {
      if (typeof unsub == "function") {
        unsub();
      }
    });
  },
};
</script>
